import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'fr',
    gite: null
  },
  mutations: {
    MUTATION_LANG (state, payload) {
      state.lang = payload
    },
    MUTATION_GITE (state, payload) {
      state.gite = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
