import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lespiedsdansleau',
    name: 'Lespiedsdansleau',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lespiedsdansleau.vue')
  },
  {
    path: '/souslesembruns',
    name: 'Souslesembruns',
    component: () => import(/* webpackChunkName: "about" */ '../views/Souslesembruns.vue')
  },
  {
    path: '/lentredeuxphares',
    name: 'Lentredeuxphares',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lentredeuxphares.vue')
  },
  {
    path: '/lacapitainerie',
    name: 'Lacapitainerie',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lacapitainerie.vue')
  },
  {
    path: '/lacapitainerie2',
    name: 'Lacapitainerie2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lacapitainerie2.vue')
  },
  {
    path: '/leboisflotte',
    name: 'LeBoisFlotte',
    component: () => import(/* webpackChunkName: "about" */ '../views/LeBoisFlotte.vue')
  },
  {
    path: '/lamaisondharold',
    name: 'LaMaisonDHarold',
    component: () => import(/* webpackChunkName: "about" */ '../views/LaMaisonDHarold.vue')
  },
  {
    path: '/activites',
    name: 'Activites',
    component: () => import(/* webpackChunkName: "about" */ '../views/Activites.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
