<template>
  <div id="app">
    <div id="nav">
      <img src="./assets/logo.png" alt="">
      <div class="containerLien">
        <router-link to="/"><span v-if="$store.state.lang == 'fr'">Accueil</span><span v-if="$store.state.lang == 'us'">Home</span></router-link>
        <a class="nosGites"><span v-if="$store.state.lang == 'fr'">Nos gîtes</span> <span v-if="$store.state.lang == 'us'">Our accommodations</span></a>
        <router-link to="/activites"><span v-if="$store.state.lang == 'fr'">Activités</span> <span v-if="$store.state.lang == 'us'">Activities</span></router-link>
        <router-link to="/contact"><span v-if="$store.state.lang == 'fr'">Nous contacter</span><span v-if="$store.state.lang == 'us'">Contact us</span></router-link>
        <a class="lang"><span class="flag-icon" :class="'flag-icon-'+$store.state.lang"></span> {{ $store.state.lang.toUpperCase() }}</a>
        <div class="dropdown-content">
          <router-link to="/lespiedsdansleau">Les Pieds Dans l'Eau</router-link>
          <router-link to="/souslesembruns">Sous Les Embruns</router-link>
          <router-link to="/lentredeuxphares">L'Entre Deux Phares</router-link>
          <router-link to="/lacapitainerie">La Capitainerie n°1</router-link>
          <router-link to="/lacapitainerie2">La Capitainerie n°2</router-link>
          <router-link to="/leboisflotte">Le Bois Flotté</router-link>
          <router-link to="/lamaisondharold">La Maison D'Harold</router-link>
        </div>
        <div class="dropdown-lang">
          <a @click="lang('fr')"><span class="flag-icon flag-icon-fr"> </span> FR</a>
          <a @click="lang('us')"><span class="flag-icon flag-icon-us"> </span> US</a>
        </div>
      </div>
    </div>

    <div class="btn-nav" @click="toggleMenu()">
      <div class="barre1"></div>
      <div class="barre2"></div>
      <div class="barre3"></div>
    </div>

    <div class="navResp">
      <ul>
        <li @click="toggleMenu()"><router-link to="/"><span v-if="$store.state.lang == 'fr'">Accueil</span><span v-if="$store.state.lang == 'us'">Home</span></router-link></li>
        <li @click="menuGitesResp()"><a><span v-if="$store.state.lang == 'fr'">Nos gîtes</span> <span v-if="$store.state.lang == 'us'">Our accommodations</span></a></li>
        <li @click="toggleMenu()"><router-link to="/activites"><span v-if="$store.state.lang == 'fr'">Activités</span> <span v-if="$store.state.lang == 'us'">Activities</span></router-link></li>
        <li @click="toggleMenu()"><router-link to="/contact"><span v-if="$store.state.lang == 'fr'">Nous contacter</span><span v-if="$store.state.lang == 'us'">Contact us</span></router-link></li>
        <li><a @click="lang('fr')"><span class="flag-icon flag-icon-fr"> </span> FR</a></li>
        <li><a @click="lang('us')"><span class="flag-icon flag-icon-us"> </span> US</a></li>
      </ul>

      <div class="dropdown-content2">
        <ul>
          <li @click="menuGitesResp()"><i class="fas fa-arrow-circle-left"></i></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/lespiedsdansleau">Les Pieds Dans l'Eau</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/souslesembruns">Sous Les Embruns</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/lentredeuxphares">L'Entre Deux Phares</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/lacapitainerie">La Capitainerie n°1</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/lacapitainerie2">La Capitainerie n°2</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/leboisflotte">Le Bois Flotté</router-link></li>
          <li @click="menuGitesResp(); toggleMenu()"><router-link to="/lamaisondharold">La Maison D'Harold</router-link></li>
        </ul>
      </div>
    </div>

    <transition name="fade">
      <router-view/>
    </transition>

    <button id="btnScroll" @click="scrollTop()"><i class="fas fa-chevron-up"></i></button>

    <footer>
      <div class="part1">
        <img src="./assets/arabesq.png" alt="" class="a1">
        <div class="txtFooterCont text-center">
          <h5>L'Entre Deux Phares</h5>
          <p>Gîtes <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
          <p>Géraldine LEVILLY <br> +33 (0)6 48 71 02 78</p>
        </div>
        <img src="./assets/arabesq2.png" alt="" class="a2">
      </div>
      <div class="part2 text-center">
        <p>L'Entre Deux Phares 2021 &copy; - Tous droits réservés</p>
        <p>Developpé par l'<a href="https://agence-brome.fr/" target="blank">Agence-brome.fr</a></p>
      </div>
    </footer>
  </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

#nav {
  position: fixed;
  width: 100%;
  background-color: rgba(247, 247, 247, 0);
  transition: 0.3s;
  z-index: 100;
}

#nav img{
  width: 45px;
  display: inline-block;
  opacity: 0;
  transition: 0.3s;
}

#nav a {
  color: #ffffff;
  margin: auto 15px;
  text-transform: uppercase;
  position: relative;
  padding: 5px 0;
  text-decoration: none;
  cursor: pointer;
  text-shadow: rgb(186, 186, 186) .5px .5px;
}

#nav a:before, #nav a.router-link-exact-active:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

#nav a:hover:before, #nav a.router-link-exact-active:before{
  visibility: visible;
  transform: scaleX(1);
}

#nav a:after, #nav a.router-link-exact-active:after{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

#nav a:hover:after, #nav a.router-link-exact-active:after{
  visibility: visible;
  transform: scaleX(1);
}

.containerLien{
  float: right;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  width: 650px;
  transition: 0.6s;
  padding: 50px 0;
}

.nosGites:hover ~ .dropdown-content, .dropdown-content:hover, .lang:hover ~ .dropdown-lang, .dropdown-lang:hover{
  visibility: visible;
  opacity: 1;
}

.dropdown-content2Actif{
  visibility: visible!important;
  opacity: 1!important;
}

.dropdown-content, .dropdown-lang {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  background-color: #848B9A;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content{
  margin-top: 3px;
  margin-left: 100px;
  min-width: 160px;
}

.dropdown-lang{
  margin-top: 3px;
  margin-left: 520px;
  min-width: 100px;
}

.dropdown-content a, .dropdown-lang a {
  padding: 12px 16px!important;
  display: block;
  margin: 2px auto!important;
}

.sticky {
  background-color: #848B9A!important;
  box-shadow: black 2px 2px 5px;
}

.sticky img{
  opacity: 1!important;
  margin-left: 25px;
  padding: 7px 0;
}

.sticky .containerLien{
  padding: 20px 0;
}

.btn-nav {
  margin: 5px 10px 10px;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 150;
  top: 8px;
  right: 0;
  overflow: hidden;
  position: fixed;
}

.barre1, .barre2, .barre3 {
  width: 30px;
  height: 5px;
  background: #fff;
  margin-top: 5px;
  transition: 1s;
}

.barre1.action {
  margin-top: 15px;
  transform: rotate(-45deg);
}

.barre2.action {
  margin-left: 50px;
}

.barre3.action {
  margin-top: -15px;
  transform: rotate(45deg);
}

.navResp{
  width: 100%;
  height: 100vh;
  background-color: #848B9A;
  position: fixed;
  z-index: 100;
  transition: .5s;
  left: -100%;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color:white;
}

.navResp ul{
  list-style: none;
  position: absolute;
  top:50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.navResp li{
  margin: 40px 0;
}

.navResp a{
  color: #ffffff;
  font-size: 30px;
  text-decoration: none;
}

.navResp a:hover{
  color: #ffffff;
  text-decoration: none;
}

.navResp a.router-link-exact-active{
  text-decoration: underline;
}

.dropdown-content2{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  width: 100%;
  height: 100vh;
  background-color: #848B9A;
  position: absolute;
  line-height: 1.2;
}

.dropdown-content2 li{
  margin: 30px 0;
}

.dropdown-content2 li a{
  font-size: 18px;
}

.fa-arrow-circle-left{
  font-size: 50px;
}

h2{
  font-family: 'Kaushan Script', cursive;
}

#btnScroll {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #848B9A;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear, background-color 0.2s;
}

#btnScroll.active {
  visibility: visible;
  opacity: 1;
}

#btnScroll:hover {
  background-color: #555;
}

.warn{
  text-align: center;
  width: 60%;
  margin: 0 auto 80px auto;
  padding: 20px;
  background-color: rgb(235, 235, 235);
  border: 2px solid rgb(148, 148, 148);
  border-radius: 10px;
}

.warn hr{
  width: 70%;
  border: none;
  height: 2px;
  background-color: rgb(148, 148, 148);
}

footer{
  font-family: "raleway";
  color: white;
}

footer .part1{
  width: 100%;
  background-color: #43454C;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

footer .part2{
  width: 100%;
  background-color: #313338;
  padding-top: 16px;
  padding-bottom: 1px;
}

footer .part2 p{
  font-size: 13px;
}

footer img{
  width: 125px;
}

footer svg{
  margin: 0 3px;
}

@media screen and (max-width: 600px) {
  .containerLien{
    display: none;
  }

  .a1, .a2{
    display: none;
  }

  .btn-nav{
    display: block;
  }

  footer .part1{
    text-align: center;
    display:block;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
export default {
  name: 'App',
  data () {
    return {
      navbar: null,
      btnScroll: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.stickyBar)
    this.navbar = document.getElementById('nav')
    this.btnScroll = document.getElementById('btnScroll')
  },
  methods: {
    stickyBar (e) {
      if (window.pageYOffset >= 1) {
        this.navbar.classList.add('sticky')
        this.btnScroll.classList.add('active')
      } else {
        this.navbar.classList.remove('sticky')
        this.btnScroll.classList.remove('active')
      }
    },
    toggleMenu () {
      const btnNav = document.querySelector('.btn-nav')
      const barre1 = document.querySelector('.barre1')
      const barre2 = document.querySelector('.barre2')
      const barre3 = document.querySelector('.barre3')
      const navResp = document.querySelector('.navResp')

      if (btnNav.classList.contains('btnActive')) {
        btnNav.classList.remove('btnActive')
        barre1.classList.remove('action')
        barre2.classList.remove('action')
        barre3.classList.remove('action')
        navResp.style.left = '-100%'
      } else {
        btnNav.classList.add('btnActive')
        barre1.classList.add('action')
        barre2.classList.add('action')
        barre3.classList.add('action')
        navResp.style.left = '0'
      }
    },
    menuGitesResp () {
      const menu = document.querySelector('.dropdown-content2')
      if (menu.classList.contains('dropdown-content2Actif')) {
        menu.classList.remove('dropdown-content2Actif')
      } else {
        menu.classList.add('dropdown-content2Actif')
      }
    },
    lang (lang) {
      this.$store.commit('MUTATION_LANG', lang)
    },
    scrollTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>
