import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'

import 'bootstrap'
import { PopoverPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'flag-icon-css/css/flag-icon.css'
global.$ = jQuery

Vue.use(PopoverPlugin)
Vue.config.productionTip = false
window.$ = window.jQuery = require('jquery')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
